import "./style.scss";

function videoContainerWidth() {

  let vwWidth = document.documentElement.clientWidth;
  let vhHeight = document.documentElement.clientHeight;

  let pageContainer = document.querySelector('[data-video-max-container]');

  if(typeof(pageContainer) != 'undefined' && pageContainer != null) {
    let viewportRatio = (vhHeight/vwWidth) * 100;
    if((viewportRatio - 56.25) < 0) {
      let maxWidth = 1.7778 * viewportRatio;
      pageContainer.style.maxWidth = '' + maxWidth + '%';
      pageContainer.classList.remove("crop-video");
    } else {
      pageContainer.style.maxWidth = '100%';
      pageContainer.classList.add("crop-video");
    }
  }
}
window.addEventListener('resize', function(event){
  videoContainerWidth();
});
document.addEventListener('DOMContentLoaded', function(event){
  videoContainerWidth();
});

let videoBg = document.querySelector('#videoBG');
if(typeof(videoBg) != 'undefined' && videoBg != null) {
  setTimeout(function () { videoBg.play(); });
}


var OSName="Unknown OS";
if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";
if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX";
if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux";
if (navigator.appVersion.indexOf("iOS")!=-1) OSName="iOS";

if ((OSName == 'MacOS') || (OSName == 'iOS')) {
  document.getElementById('body').classList.add("detected");
}

// aktuelle Video Werte: 1920x1080 = 56.25%
// Ziel Video passt immer in den Viewport
// --> Video Container ist so lange 100% breit, solange das Video ganz sichtbar ist. (Viewport Ratio > 56.25%)
// --> wenn < 56.25%, dann beträgt die maximale Containerbreite Viewport Ratio * 1.7778 (100/56.25)
